export enum AppColor {
  WHITE = "#FFFFFF",
  OLD_PRIMARY = "#26AE60",
  NEW_PRIMARY = "#C3F533",
  COLOR_REGULAR_GREY = "#9D9D9D",
  COLOR_DARK_GREY = "#2C2C2C",
  COLOR_DARK = "#252525",
  DROPDOWN_BORDER = "#424242",
  LIST_BORDER = "#353535",
  OPTION_COLOR_FOCUSED = "#454545",
  OPTION_BG_SELECTED = "#3F4235",
  OPTION_COLOR_SELECTED = "#32463A",
  TRANSPARENT = "transparent",
  RED = "#FF4B55",
}
