import {
  Nullable,
  CurrencyRatesTable,
  ExchangersTable,
  CryptoType,
  CityWithCountry,
} from "./type";

export type GlobalContextState = {
  crypto: CryptoType[];
  link: string;
  currency_pairs: [CryptoType, CryptoType];
  amount: number;
  activeTab: string;
  paginationPage: number;
  currencyRatesTable: CurrencyRatesTable;
  exchangersTable: ExchangersTable;
  currentCity: Nullable<CityWithCountry>;
};

export enum GlobalContextKind {
  CRYPTO = "CRYPTO",
  LINK = "LINK",
  PAIRS = "PAIRS",
  AMOUNT = "AMOUNT",
  GETCURRENCY = "GETCURRENCY",
  TAB = "TAB",
  CURRENCY_RATES_TABLE = "CURRENCY_RATES_TABLE",
  EXCHANGERS_TABLE = "EXCHANGERS_TABLE",
  CURRENT_CITY = "CURRENT_CITY",
}
