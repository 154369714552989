import { Noto_Sans as NotoSans } from "next/font/google";
import classNames from "classnames";
import { ReactNode } from "react";

const notoSans = NotoSans({
  weight: ["400", "500", "700"],
  style: ["normal"],
  subsets: ["latin"],
});

interface TypographyProps {
  as: keyof JSX.IntrinsicElements;
  children: ReactNode;
  addClass?: string;
  id?: string;
}

export default function Typography({
  as: CustomTag,
  children,
  addClass,
  id,
}: TypographyProps) {
  const className = classNames(notoSans.className, addClass);
  return (
    <CustomTag id={id} className={className}>
      {children}
    </CustomTag>
  );
}
