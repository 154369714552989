import { useNavigateToHome } from "app/hooks/useNavigateToHome";
import LogoSRC from "@public//svg/logo/header_logo.svg";
import { useContext } from "react";
import { GlobalContext } from "@context/GlobalContext";
import { GlobalContextKind } from "@common/type/GlobalContext.type";
import { initialPair } from "@components/layout/Header/constants";
import styles from "./Logo.module.scss";

export default function AppLogo() {
  const { wrapper, logo, logo__img } = styles;
  const { dispatch } = useContext(GlobalContext);

  const navigateHome = useNavigateToHome();

  const onClick = () => {
    dispatch({ type: GlobalContextKind.PAIRS, payload: initialPair });
  };

  return (
    <div className={wrapper}>
      <div className={logo}>
        <LogoSRC
          className={logo__img}
          onClick={() => navigateHome(onClick)}
          aria-hidden="true"
        />
      </div>
    </div>
  );
}
