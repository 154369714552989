import urlRegex from "url-regex";
import Image from "next/image";
import QuestionImg from "@public/svg/icons/question_mark.svg";

type Props = {
  w?: number;
  h?: number;
  src: string;
  alt: string;
};

export default function CurrencyIcon({ w = 32, h = w, src, alt }: Props) {
  const isValidUrl = urlRegex().test(src);

  return (
    <div>
      {isValidUrl ? (
        <Image width={w} height={h} src={src} alt={alt} />
      ) : (
        <QuestionImg />
      )}
    </div>
  );
}
