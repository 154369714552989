export enum AppRoute {
  EXCHANGE = "exchange",
  RATES = "rates",
  HOMERATES = "home",
  PARTNERS = "partners",
  FEEDBACK = "feedback",
  TERMS = "terms",
  POLICY = "policy",
  COOKIES = "cookies",
  EXCHANGERS = "exchangers",
  HOME = "/",
  NOT_FOUND = "404",
  SERVER_ERROR = "500",
}
