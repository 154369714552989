type Props = {
  fill?: string;
  width?: number;
  height?: number;
};

export default function ExchangeIcon({
  width = 32,
  height = width,
  fill = "#C3F533",
}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <path
        d="M16 2.6665C8.63996 2.6665 2.66663 8.63984 2.66663 15.9998C2.66663 23.3598 8.63996 29.3332 16 29.3332C23.36 29.3332 29.3333 23.3598 29.3333 15.9998C29.3333 8.63984 23.36 2.6665 16 2.6665ZM17.3333 22.6665H14.6666V19.9998H17.3333V22.6665ZM17.3333 17.3332H14.6666V9.33317H17.3333V17.3332Z"
        fill={fill}
      />
    </svg>
  );
}
