import { ButtonHTMLAttributes } from "react";
import classNames from "classnames";
import styles from "./Button.module.scss";

type Props = ButtonHTMLAttributes<HTMLButtonElement>;

export default function Button({
  children,
  className,
  onClick,
  disabled = false,
  ...props
}: Props) {
  return (
    <button
      className={classNames(styles.button, className)}
      type="button"
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
}
