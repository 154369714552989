import { useEffect, useState } from "react";
import Link from "next/link";
import classNames from "classnames";
import { useTranslation } from "next-i18next";
import Cookies from "js-cookie";
import { Button } from "@components/common/Button";
import Typography from "@components/common/Typography";
import { COOKIE_AGREEMENT_NAME } from "@common/constant/constant";
import { createConsent } from "@api/create-consent.api";
import { getIp } from "@api/getIp.api";
import CookieIcon from "@public/svg/icons/cookie.svg";
import styles from "./Cookie.module.scss";

export default function CookieAlert() {
  const [showBanner, setShowBanner] = useState(true);
  const [smoothHide, setSmoothHide] = useState(false);

  const { t } = useTranslation("common");

  useEffect(() => {
    if (Cookies.get(COOKIE_AGREEMENT_NAME)) {
      setShowBanner(false);
    }
  }, []);

  const handleAccept = async () => {
    Cookies.set(COOKIE_AGREEMENT_NAME, "accepted", { expires: 365 });

    const ip = await getIp();

    setSmoothHide(true);
    createConsent({ ip, cookie_policy: true });
  };

  const handleAnimation = () => {
    if (smoothHide) {
      setShowBanner(false);
    }
  };

  return (
    <div
      className={classNames(
        styles.wrapper,
        !showBanner && styles.hidden,
        smoothHide && styles.hide
      )}
      onAnimationEnd={handleAnimation}
    >
      <CookieIcon />
      <div className={styles.cookie_text}>
        <Typography as="p" addClass={styles.cookie_text__title}>
          {t("cookie-title")}
        </Typography>
        <Link href="/cookies">
          <Typography as="p" addClass={styles.cookie_text__link}>
            {t("cookie-link")}
          </Typography>
        </Link>
      </div>
      <Button onClick={handleAccept} className={styles.cookie_btn}>
        {t("cookie-button")}
      </Button>
    </div>
  );
}
