import { useState } from "react";
import Navbar from "@components/ui/Navbar";
import BornInUkraineLogo from "@components/common/Logo/BornInUkraine";
import AppLogo from "@components/common/Logo/App";
import Language from "@components/ui/Language";
import BurgerIcon from "@public/svg/icons/burger_menu.svg";
import classNames from "classnames";
import dynamic from "next/dynamic";
import styles from "./Header.module.scss";

const DynamicBurger = dynamic(() => import("@components/ui/BurgerMenu"));

export default function Header() {
  const [isOpen, setOpen] = useState<boolean>(false);

  const handlerBurger = () => {
    setOpen(!isOpen);
  };

  return (
    <header className={classNames(styles.wrapper, isOpen && styles.front)}>
      <div className={styles.container}>
        <div className={styles.logo_container}>
          <AppLogo />
        </div>
        <div className={styles.navbar_container}>
          <BornInUkraineLogo />
          <Navbar />
        </div>
        <div className={styles.burger_container}>
          <Language />
          <button
            className={styles.logo__burger}
            type="button"
            onClick={handlerBurger}
            aria-label="burger"
          >
            <BurgerIcon />
          </button>
        </div>
        {isOpen && <DynamicBurger onClick={handlerBurger} />}
      </div>
    </header>
  );
}
