import { CurrencyCategory } from "@common/enum/enum";
import { CryptoPair, CryptoType } from "@common/type/type";

export const EMPTY_CRYPTO: CryptoType = {
  abbreviation: "",
  id: "",
  name: "",
  image: "",
  category: CurrencyCategory.CRYPTO,
};

export const EMPTY_PAIR: CryptoPair = [EMPTY_CRYPTO, EMPTY_CRYPTO];
