type Props = {
  fill?: string;
  width?: number;
  height?: number;
  rotate?: number;
};

export default function ArrowIcon({
  fill = "#C3F533",
  width = 18,
  height = width,
  rotate = 0,
}: Props) {
  return (
    <div style={{ width, height }}>
      <svg
        style={{ rotate: `${rotate}deg` }}
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
      >
        <path
          d="M5.5575 6.44238L9 9.87738L12.4425 6.44238L13.5 7.49988L9 11.9999L4.5 7.49988L5.5575 6.44238Z"
          fill={fill}
        />
      </svg>
    </div>
  );
}
