import { AppColor } from "@common/enum/AppColor.enum";

type Props = {
  fill?: string;
  width?: number;
  height?: number;
};

export default function VerifyIcon({
  fill = AppColor.NEW_PRIMARY,
  width = 30,
  height = width,
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame 5169">
        <path
          id="Star 7"
          d="M13.0541 1.65824C14.1754 0.702701 15.8246 0.702702 16.9459 1.65824L18.8531 3.28355C19.3335 3.69302 19.931 3.94048 20.5603 3.9907L23.0581 4.19003C24.5267 4.30722 25.6928 5.47335 25.81 6.94188L26.0093 9.43974C26.0595 10.069 26.307 10.6665 26.7165 11.1469L28.3418 13.0541C29.2973 14.1754 29.2973 15.8246 28.3418 16.9459L26.7165 18.8531C26.307 19.3335 26.0595 19.931 26.0093 20.5603L25.81 23.0581C25.6928 24.5267 24.5266 25.6928 23.0581 25.81L20.5603 26.0093C19.931 26.0595 19.3335 26.307 18.8531 26.7165L16.9459 28.3418C15.8246 29.2973 14.1754 29.2973 13.0541 28.3418L11.1469 26.7165C10.6665 26.307 10.069 26.0595 9.43974 26.0093L6.94188 25.81C5.47335 25.6928 4.30722 24.5266 4.19003 23.0581L3.9907 20.5603C3.94048 19.931 3.69302 19.3335 3.28355 18.8531L1.65824 16.9459C0.702701 15.8246 0.702702 14.1754 1.65824 13.0541L3.28355 11.1469C3.69302 10.6665 3.94048 10.069 3.9907 9.43974L4.19003 6.94188C4.30722 5.47335 5.47335 4.30722 6.94188 4.19003L9.43974 3.9907C10.069 3.94048 10.6665 3.69302 11.1469 3.28355L13.0541 1.65824Z"
          fill={fill}
        />
        <path
          id="Vector 9"
          d="M9 15.625L13.7273 19L22 10"
          stroke="#151515"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}
