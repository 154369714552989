export const DATA_TIME_SELECT = [
  {
    value: 1,
    title: "one",
  },
  {
    value: 6,
    title: "six",
  },
  {
    value: 12,
    title: "twelve",
  },
  {
    value: 24,
    title: "day",
  },
  {
    value: 168,
    title: "week",
  },
  {
    value: 720,
    title: "month",
  },
];
