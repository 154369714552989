import { useContext } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import classNames from "classnames";
import { GlobalContext } from "@context/GlobalContext";
import { GlobalContextKind } from "@common/type/type";
import { AppRoute } from "@common/enum/enum";
import Logo from "@public/svg/icons/logo.svg";
import { Roboto } from "next/font/google";
import Typography from "@components/common/Typography";
import { useRecomendationsData } from "app/hooks/use-recomendations-data";
import dynamic from "next/dynamic";
import Socials from "./Socials";
import styles from "./Footer.module.scss";

const DynamicRecommendations = dynamic(
  () => import("../../common/Recomendations")
);

const roboto = Roboto({
  weight: ["400", "500", "700"],
  style: ["normal"],
  subsets: ["latin"],
});

const linkMapping = [
  { link: "/terms", title: "conditions" },
  { link: "/policy", title: "privacy" },
  { link: "/cookies", title: "cookies" },
];

export default function Footer() {
  const router = useRouter();
  const { dispatch } = useContext(GlobalContext);
  const { t } = useTranslation(["footer"]);
  const { isSuccess, data } = useRecomendationsData();

  const goToMainPage = () => {
    dispatch({ type: GlobalContextKind.LINK, payload: "" });
    router.push(AppRoute.HOME);
  };

  return (
    <footer className={styles.wrapper}>
      {isSuccess && Boolean(data?.length) && (
        <div className={styles.content}>
          <DynamicRecommendations recomendations={data} />
        </div>
      )}
      <div className={styles.divisor} />
      <div className={classNames(styles.content, styles.content__grid)}>
        <div className={styles.logo}>
          <Logo className={styles.logo__img} onClick={goToMainPage} />
          <Typography
            as="p"
            addClass={classNames(styles.logo__title, roboto.className)}
          >
            ©{new Date().getFullYear()}, Marketexchangers.com <br />{" "}
            {t("copyright")}
          </Typography>
          <div className={styles.logo__mini_logo}>
            <div className={styles.mini_logo} />
            <Typography as="p">Born in Ukraine</Typography>
          </div>
        </div>
        <div className={styles.links}>
          <Typography as="p" addClass={styles.rules}>
            {t("rules")}
          </Typography>
          {linkMapping.map(({ link, title }) => (
            <Link key={`${link}-footer-link`} href={link}>
              <Typography as="p" addClass={styles.link_item}>
                {t(title)}
              </Typography>
            </Link>
          ))}
        </div>
        <Socials />
      </div>
    </footer>
  );
}
