import { useQuery } from "react-query";
import { getRecomendationLinks } from "@api/get-recomendation-links.api";

export const useRecomendationsData = () => {
  return useQuery({
    queryKey: "recomendations",
    queryFn: getRecomendationLinks,
    staleTime: Infinity,
  });
};
