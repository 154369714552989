import { useState } from "react";
import { useRouter } from "next/router";
import classNames from "classnames";
import LanguageIcon from "@public/svg/icons/language.svg";
import dynamic from "next/dynamic";
import styles from "./Language.module.scss";

const DynamicLanguageDropdown = dynamic(
  () => import("@components/ui/Language/LanguageDropdown")
);

type Props = {
  isBurger?: boolean;
};

export default function Language({ isBurger }: Props) {
  const {
    nav_lang,
    nav_lang__icon,
    nav_lang_select_burger,
    nav_lang_target,
    nav_lang_select,
    opened,
  } = styles;

  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);

  const classNameIcon = isBurger ? nav_lang__icon : "";
  const selectStyles = [nav_lang_select];

  const handleOptionsClose = () => {
    setIsOpen(false);
    document.removeEventListener("mouseclick", handleOptionsClose);
  };
  const handleOptionsOpen = () => {
    setIsOpen(true);
    document.addEventListener("mousedown", handleOptionsClose);
  };

  return (
    <div
      aria-hidden="true"
      onClick={handleOptionsOpen}
      className={classNames(nav_lang, isOpen && opened)}
    >
      <LanguageIcon className={classNameIcon} />
      <div
        className={classNames(selectStyles, isBurger && nav_lang_select_burger)}
      >
        <span className={nav_lang_target}>{router.locale}</span>
        {isOpen && <DynamicLanguageDropdown />}
      </div>
    </div>
  );
}
