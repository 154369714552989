import { useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import Language from "@components/ui/Language";
import Typography from "@components/common/Typography";
import { GlobalContext } from "@context/GlobalContext";
import { AppRoute } from "@common/enum/AppRoute.enum";
import { extraAppRoutes } from "@common/constant/constant";
import { GlobalContextKind } from "@common/type/type";
import styles from "./Navbar.module.scss";

const navbarList = ["exchange", "partners", "feedback"];

export default function Navbar() {
  const { navbar, isActive } = styles;

  const router = useRouter();
  const [menuItem, setMenuItem] = useState<string>("");
  const { dispatch } = useContext(GlobalContext);
  const { t } = useTranslation(["header"]);

  const isExtraRoute = extraAppRoutes.some(route => route === router.pathname);

  useEffect(() => {
    const path = isExtraRoute ? "/feedback" : router.pathname;

    setMenuItem(path);
    dispatch({ type: GlobalContextKind.LINK, payload: path });
  }, [dispatch, router.pathname]);

  const handlerMenuItem = (event: React.MouseEvent) => {
    const { id } = event.currentTarget as HTMLDivElement;

    setMenuItem(id);
    dispatch({ type: GlobalContextKind.LINK, payload: id });

    if (id === AppRoute.EXCHANGE) {
      router.push(
        {
          pathname: `/${AppRoute.EXCHANGE}`,
        },
        undefined,
        { shallow: true }
      );
    } else {
      router.push(`/${id}`);
    }
  };

  const isActiveLink = (item: string) => {
    const hasItem = menuItem.includes(`/${item}`);

    return hasItem ? isActive : "";
  };

  return (
    <nav className={navbar}>
      {navbarList.map(item => {
        return (
          <div
            id={item}
            onClick={handlerMenuItem}
            aria-hidden="true"
            key={item}
          >
            <Typography as="p" addClass={isActiveLink(item)}>
              {t(item)}
            </Typography>
          </div>
        );
      })}
      <Language />
    </nav>
  );
}
