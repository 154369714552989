import { GlobalContextKind, GlobalContextState } from "@common/type/type";
import { LoadingMode } from "@common/enum/enum";
import { DEFAULT_CURRENCY_TABLE_LIMIT } from "@common/constant/constant";
import { GlobalContextAction } from "./GlobalContextAction";


export const GlobalContextReducer = (
  state: GlobalContextState,
  action: GlobalContextAction
): GlobalContextState => {
  const { type, payload } = action;

  switch (type) {
    case GlobalContextKind.CRYPTO: {
      return {
        ...state,
        crypto: [...payload],
      };
    }

    case GlobalContextKind.LINK: {
      return {
        ...state,
        link: payload,
      };
    }

    case GlobalContextKind.PAIRS: {
      return {
        ...state,
        currency_pairs: payload,
        currencyRatesTable: {
          order: true,
          filter: "avg_rating",
          offset: 0,
          limit: DEFAULT_CURRENCY_TABLE_LIMIT,
          loadingMode: LoadingMode.INITIAL,
        },
        currentCity: undefined,
        paginationPage: 1,
      };
    }
    case GlobalContextKind.AMOUNT: {
      return {
        ...state,
        amount: payload,
      };
    }

    case GlobalContextKind.TAB: {
      return {
        ...state,
        activeTab: payload,
      };
    }

    case GlobalContextKind.CURRENCY_RATES_TABLE: {
      const updatedData = { ...state.currencyRatesTable, ...payload };

      return {
        ...state,
        currencyRatesTable: updatedData,
      };
    }

    case GlobalContextKind.EXCHANGERS_TABLE: {
      const updatedData = { ...state.exchangersTable, ...payload };

      return {
        ...state,
        exchangersTable: updatedData,
      };
    }

    case GlobalContextKind.CURRENT_CITY: {
      const updatedTable = {
        ...state.currencyRatesTable,
        loadingMode: LoadingMode.COVER,
      };

      return {
        ...state,
        currentCity: payload,
        currencyRatesTable: updatedTable,
      };
    }

    default:
      return state;
  }
};
