import { useRouter } from "next/router";
import { useContext } from "react";
import { GlobalContext } from "@context/GlobalContext";
import { AppRoute } from "@common/enum/enum";
import { GlobalContextKind } from "@common/type/type";

export const useNavigateToHome = () => {
  const router = useRouter();
  const { dispatch } = useContext(GlobalContext);

  return (onClick?: () => void) => {
    if (router.pathname !== AppRoute.HOME) {
      dispatch({ type: GlobalContextKind.LINK, payload: "" });
      onClick?.();
      router.push(AppRoute.HOME);
    }
  };
};
