import { AppColor } from "@common/enum/AppColor.enum";

type Props = {
  fill?: string;
  width?: number;
  height?: number;
};

export default function OrderIcon({
  fill = AppColor.NEW_PRIMARY,
  width = 18,
  height = width,
}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <g clipPath="url(#clip0_4697_6593)">
        <path
          d="M2.25 13.5H6.75V12H2.25V13.5ZM2.25 4.5V6H15.75V4.5H2.25ZM2.25 9.75H11.25V8.25H2.25V9.75Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_4697_6593">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
