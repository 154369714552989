import Link from "next/link";
import { useTranslation } from "next-i18next";
import Typography from "@components/common/Typography";
import TwitterLogo from "@public/svg/icons/twitter.svg";
import InstagramLogo from "@public/svg/icons/instagram.svg";
import TelegramLogo from "@public/svg/icons/telegram.svg";
import styles from "./Socials.module.scss";

export default function Socials() {
  const { t } = useTranslation(["footer"]);
  const { socials, socials_links } = styles;

  const socialList = [
    {
      id: "telegram",
      link: "https://t.me/marketexchangerscom",
      logo: <TelegramLogo />,
    },
    {
      id: "instagram",
      link: "https://www.instagram.com/marketexchangers/",
      logo: <InstagramLogo />,
    },
    {
      id: "twitter",
      link: "https://twitter.com/marketexchangrs",
      logo: <TwitterLogo />,
    },
  ];

  return (
    <div className={socials}>
      <Typography as="p">{t("socials")}</Typography>
      <div className={socials_links}>
        {socialList.map(({ link, logo, id }) => (
          <Link key={link} href={link} target="_blank" aria-label={id}>
            {logo}
          </Link>
        ))}
      </div>
    </div>
  );
}
