import Typography from "@components/common/Typography";
import MiniLogo from "@public/svg/icons/ua_icon.svg";
import styles from "./BornInUkraine.module.scss";

export default function BornInUkraineLogo() {
  const { logo } = styles;

  return (
    <div className={logo}>
      <MiniLogo />
      <Typography as="p">Born in Ukraine</Typography>
    </div>
  );
}
