import { ChatIcon } from "@components/common/Icon";
import Typography from "@components/common/Typography";
import Button from "../CommonButton";
import styles from "./OutlinedButton.module.scss";

type Props = {
  title: string;
  onClick: () => void;
};

export default function OutlinedButton({ title, onClick }: Props) {
  return (
    <Button onClick={onClick} className={styles.review_button}>
      <ChatIcon />
      <Typography as="p" addClass={styles.review_title}>
        {title}
      </Typography>
    </Button>
  );
}
