import { createContext, Dispatch } from "react";
import { GlobalContextAction } from "@reducer/GlobalContextAction";
import { GlobalContextState } from "@common/type/type";
import {
  DEFAULT_CURRENCY_TABLE_LIMIT,
  EMPTY_PAIR,
} from "@common/constant/constant";
import { LoadingMode } from "@common/enum/LoadingMode.enum";

export const initialState: GlobalContextState = {
  crypto: [],
  link: "",
  currency_pairs: EMPTY_PAIR,
  amount: 1,
  activeTab: "offices",
  paginationPage: 1,
  currencyRatesTable: {
    order: true,
    filter: "avg_rating",
    limit: DEFAULT_CURRENCY_TABLE_LIMIT,
    offset: 0,
    loadingMode: LoadingMode.INITIAL,
  },
  exchangersTable: {
    order: true,
    filter: "rating",
    limit: 10,
    offset: 0,
    loadingMode: LoadingMode.INITIAL,
  },
  currentCity: undefined,
};

export const GlobalContext = createContext<{
  state: GlobalContextState;
  dispatch: Dispatch<GlobalContextAction>;
}>({ state: initialState, dispatch: () => null });
