import Typography from "@components/common/Typography";
import styles from "./TextButton.module.scss";

type Props = {
  title: string | JSX.Element;
  icon?: JSX.Element;
  onClick: () => void;
};

export default function TextButton({ title, icon, onClick }: Props) {
  return (
    <button type="button" className={styles.back_button} onClick={onClick}>
      {icon}
      <Typography as="p" addClass={styles.back_title}>
        {title}
      </Typography>
    </button>
  );
}
