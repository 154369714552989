type Props = {
  fill?: string;
  width?: number;
  height?: number;
};

export default function DropdownArrowIcon({
  fill = "#595959",
  width = 12,
  height = 8,
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.59 0.09L6 4.67L1.41 0.0899992L5.24537e-07 1.5L6 7.5L12 1.5L10.59 0.09Z"
        fill={fill}
      />
    </svg>
  );
}
