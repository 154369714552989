type Props = {
  fill?: string;
  width?: number;
  height?: number;
};

export default function ChatIcon({
  fill = "#C3F533",
  width = 18,
  height = width,
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="chat">
        <path
          id="Chat Icon"
          d="M3.5 3H15.5V12H4.3775L3.5 12.8775V3ZM3.5 1.5C2.675 1.5 2.0075 2.175 2.0075 3L2 16.5L5 13.5H15.5C16.325 13.5 17 12.825 17 12V3C17 2.175 16.325 1.5 15.5 1.5H3.5ZM5 9H11V10.5H5V9ZM5 6.75H14V8.25H5V6.75ZM5 4.5H14V6H5V4.5Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}
