import { PropsWithChildren } from "react";
import classNames from "classnames";
import Header from "../Header";
import Footer from "../Footer";
import styles from "./Wrapper.module.scss";

type Props = PropsWithChildren;

function Wrapper({ children }: Props) {
  return (
    <>
      <div className={classNames(styles.content_wrapper)}>
        <Header />
        {children}
        <Footer />
      </div>
      <div className={styles.back_layer} />
    </>
  );
}

export default Wrapper;
