type Props = {
  fill?: string;
  width?: number;
  height?: number;
};

export default function BackIcon({
  fill = "#C3F533",
  width = 18,
  height = width,
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="reply">
        <path
          id="Vector"
          d="M7.5 6.75V3.75L2.25 9L7.5 14.25V11.175C11.25 11.175 13.875 12.375 15.75 15C15 11.25 12.75 7.5 7.5 6.75Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}
