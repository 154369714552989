import { CryptoPair } from "@common/type/CryptoData.type";

export const initialPair = [
  {
    id: "63d9570a-a866-43fb-a31a-ccc3defded96",
    abbreviation: "USDTTRC20",
    name: "Tether TRC20",
    image:
      "https://assets.coingecko.com/coins/images/325/large/Tether.png?1696501661",
    category: "crypto",
  },

  {
    id: "",
    abbreviation: "",
    name: "",
    image: "",
    category: "crypto",
  },
] as CryptoPair;
