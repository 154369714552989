import { ButtonHTMLAttributes } from "react";
import Typography from "@components/common/Typography";
import classNames from "classnames";
import Button from "../CommonButton";
import styles from "./CancelButton.module.scss";

type Props = {
  title: string | JSX.Element;
  icon?: JSX.Element;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export default function CancleButton({
  title,
  onClick,
  icon,
  className,
  ...props
}: Props) {
  return (
    <Button
      onClick={onClick}
      {...props}
      className={classNames(styles.cancel_button, className)}
    >
      {icon}
      <Typography addClass={styles.exchange_title} as="span">
        {title}
      </Typography>
    </Button>
  );
}
