import { ConsentType } from "@common/type/ConsentData.type";
import { APIService } from "app/service/api-service";

export const createConsent = (body: ConsentType) => {
  const params = Object.entries(body).reduce((acc, [key, value]) => {
    acc.append(key, String(value));
    return acc;
  }, new URLSearchParams());

  const url = `/consent/create?${params}`;
  return APIService.post<string, ConsentType>(url, body);
};
